@use "../../config/" as *;

.waf-gallery-page {
    .preview-swiper {
        overflow: hidden;
        padding-bottom: 0.2rem;
        position: relative;

        .article {
            &-list {
                padding-bottom: 1rem;
                @include flex-config(flex, null, null, null);
            }

            &-item {
                flex-shrink: 0;
                width: 100%;
            }

            &-thumbnail {
                height: 20rem;

                .img-box {
                    height: 100%;
                    @include flex-config(flex, null, center, null);
                }
                &::after{
                    content: unset;
                }
            }
        }

        .swiper-button-prev,
        .swiper-button-next {
            width: 3rem;
            height: 3rem;
            cursor: pointer;
            position: absolute;
            bottom: 0;
            right: 0;
            top: auto;
            border-radius: 0;
            border: 0.1rem solid var(--kabaddi-primary-color);
            z-index: var(--z-index1);
            @include flex-config(flex, null, center, center);

            &:before {
                content: "";
                font-family: $font-icon;
                font-size: 1rem;
                color: var(--kabaddi-secondary-color);
            }

            &::after {
                display: none;
            }
        }

        .swiper-button-prev {
            right: 3rem;
            left: auto;
            background-color: var(--white-color);

            &:before {
                content: "\e829";
            }
        }

        .swiper-button-next {
            background-color: var(--kabaddi-primary-color);

            &:before {
                content: "\e828";
            }
        }

        .swiper-button-disabled {
            cursor: default;
        }

        .swiper-pagination {
            color: var(--kabaddi-secondary-color);
            font-size: 2rem;

            &-current {
                color: var(--kabaddi-primary-color);
            }

            &-current,
            &-total {
                font-size: 2rem;
            }
        }
    }

    .article-content {

        a {
            text-decoration: none;
        }

        .article-description {
            line-height: 1.6;
            height: 6rem;

            p {
                font: 1.4rem/1.5 $font-regular;
                color: var(--black-color);
                margin: 0;
            }
        }
    }

    .article-thumbnail {
        border-radius: var(--half-radius);
        overflow: hidden;
    }

    .thumbnail-swiper {
        .article-list {
            display: none;
        }
    }
}

@media screen and (min-width: $tablet-min-width) {
    .waf-gallery-page {
        padding-bottom: 5rem;

        .article-body {
            position: relative;
        }

        .preview-swiper {
            padding-bottom: 0;

            .article-list {
                padding-bottom: 10rem;
            }

            .article-item {
                // width: 100%;
                // flex-basis: 100%;
                margin-right: 0;
            }
            .swiper-button-prev,
            .swiper-button-next {
                position: absolute;
                margin: 0 auto;
                bottom: var(--full-space);
            }

            .swiper-button-prev {
                left: -4rem;
                right: 0;
            }
            .swiper-button-next {
                left: 0rem;
                right: -4rem;
            }
        }

        .article-content {
            .article-description {
                p {
                    font-size: 1.4rem;
                }
            }
        }

        .swiper-pagination-fraction {
            position: absolute;
            bottom: var(--full-space);
            left: 0;
            z-index: var(--z-index2);
            &:before{
                content: "";
                position: absolute;
                width: 41rem;
                height: .3rem;
                top: var(--full-space-negative);
                background-color: var(--kabaddi-primary-color);
                border-radius: var(--full-radius);
            }
        }

        .swiper-pagination-current {
            font-family: $font-bold;
            color: var(--light-red-color);
        }

        .preview-swiper {
            .article-thumbnail {
                height: 40rem;
            }
        }

        .thumbnail-swiper {
            position: absolute;
            right: 0;
            bottom: 0;
            width: calc(44% - 0.5rem);

            .article {
                &-list {
                    position: relative;
                    @include flex-config(flex, null, null, null);
                }

                &-item {
                    flex-shrink: 0;
                    width: 100%;
                    height: 100%;
                    position: relative;
                }
            }

            .swiper-button-prev,
            .swiper-button-next {
                display: none;
            }
        }
    }
}
